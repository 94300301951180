import { CONTACT, HOME, PORTFOLIO } from "../../constants/constants";

export const list = [
    {
        route: HOME,
        title: 'Acasa'
    }, {
        route: PORTFOLIO,
        title: 'Portofoliu'
    }, {
        route: CONTACT,
        title: 'Contact'
    }
];