import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MaterialIcon from 'react-google-material-icons';
import { list } from './list';
import './Header.scss';

const Header = () => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    const handleGoTo = useCallback((path) => {
        navigate(path);
        setMenuOpen(false);
    }, [navigate]);

    return (
        <header className='sp-header'>
            <div className='sp-header__logo'>
                <p>Picture Of Life Stories</p>
            </div>

            <div className='sp-header__menu' onClick={() => setMenuOpen(!menuOpen)}>
                <MaterialIcon icon="menu" />
            </div>

            {
                menuOpen &&
                <div className='sp-header__hamburger'>
                    {
                        list.map(item => (
                            <li key={item.route} onClick={() => handleGoTo(item.route)}>{item.title}</li>
                        ))
                    }
                </div>
            }

            <nav className='sp-header__nav'>
                <ul className='sp-header__nav__list'>
                    {
                        list.map(item => (
                            <li key={item.route} onClick={() => handleGoTo(item.route)}>{item.title}</li>
                        ))
                    }
                </ul>
            </nav>
        </header>
    );
};

export default Header;