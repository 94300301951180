import { WEDDING, EVENTS, COMMERCIAL, PORTRAIT } from '../../constants/categories';
import { WEEDING_PATH, PORTRAIT_PATH, COMMERCIAL_PATH, EVENTS_PATH } from '../../constants/constants';
import wedding from '../../assets/wedding.jpg';
import portrait from '../../assets/good_vibes.jpg';
import commercial from '../../assets/corporate.jpg';
import events from '../../assets/events.jpg';

export const categories = [
    {
        title: WEDDING,
        url: wedding,
        path: WEEDING_PATH
    }, {
        title: PORTRAIT,
        url: portrait,
        path: PORTRAIT_PATH
    }, {
        title: COMMERCIAL,
        url: commercial,
        path: COMMERCIAL_PATH
    }, {
        title: EVENTS,
        url: events,
        path: EVENTS_PATH
    }
];