import React from "react";
import './Section.scss';

const Section = ({ title, url, ...rest }) => {
    return (
        <div className="sp-section" {...rest}>
            <div className="sp-section__img">
                <div className="sp-section__img__content" style={{ backgroundImage: `url(${url})` }} />
            </div>
        </div>
    );
};

export default Section;