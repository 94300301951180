// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBeovlNfpnZYDZN0agKOU2U0U4tPEwY5Ao",
  authDomain: "portofolio-187b3.firebaseapp.com",
  projectId: "portofolio-187b3",
  storageBucket: "portofolio-187b3.appspot.com",
  messagingSenderId: "1069269276734",
  appId: "1:1069269276734:web:c73038fc850f799eb3196f",
  measurementId: "G-R7BCBQPFQK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
const storage = getStorage(app);

const storageRef = ref(storage);


const categories = async (path) => {
  const list = listAll(ref(storageRef, path));
  
  return list.then(async folderRef => {
    let categs = [];

  folderRef.prefixes.forEach(fold => {
    categs.push(fold.fullPath);
  });

  return categs;
  }
)};

export const getImages = async (path) => {
  const categs = await categories(path);

  const categRefs = categs.map(folder => {
    return {
      folder: folder.split('/')[1],
      ref: ref(storage, folder)
    };
  });

  const withImgRefs = await Promise.all(
    categRefs.map(async item => {
      return {
        ...item,
        items: await listAll(item.ref)
      };
    })
  );

  const withUrls = await Promise.all(
    withImgRefs.map(async item => {
      return {
        folder: item.folder,
        urls: await Promise.all(item.items.items.map(async q => await getDownloadURL(q)))
      };
    })
  );

  return withUrls;
};