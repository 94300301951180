import React, { useEffect, useMemo, useState } from "react";
import Slider from "react-slick";
import { useParams } from "react-router-dom";
import { WEDDING, PORTRAIT, COMMERCIAL, EVENTS } from "../../constants/categories";
import { getImages } from '../../firebase';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './PortfolioDetail.scss';

const PortfolioDetail = () => {
    const params = useParams();
    const [data, setData] = useState([]);

    const query = useMemo(() => {
        switch (params.path) {
            case 'wedding':
                return WEDDING;
            case 'portrait':
                return PORTRAIT;
            case 'commercial':
                return COMMERCIAL;
            case 'events':
                return EVENTS;
            default:
                return null;
        }
    }, [params]);

    useEffect(() => {
        if (query) {
            getImages(query).then(result => {
                setData(result);
            });
        }
    }, [query]);

    console.log(data);

    return (
        <div className="sp-portfolio-details">
            {
                data.map((section, index) => (
                    <section key={section.title + index.toString()}>
                        <h3>{section.folder}</h3>
                        <Slider 
                            arrows={true}
                            dots={true} 
                            infinite={true} 
                            speed={500} 
                            slidesToScroll={1} 
                            slidesToShow={1} 
                            adaptiveHeight={true} 
                            // centerMode={true}
                            autoplay={true} 
                            autoplaySpeed={2000} 
                            pauseOnFocus={true}
                            useCSS={true}
                        >
                            {
                                section.urls.map((url, idx) => (
                                    // <div >
                                        <img key={idx.toString()} src={url} alt="" style={{ width: '800px' }}/>
                                    // </div>
                                ))
                            }
                        </Slider>
                    </section>
                ))
            }
        </div>
    );
};

export default PortfolioDetail;