import React, { useCallback, useEffect, useState } from "react";
import wa from '../../assets/wa.png';
import './Contact.scss';

const Contact = () => {
    const [resp, setResp] = useState('');
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setResp('');
        }, 3000);
    });

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        setFetching(true);
        const obj = {};

        obj.name = e.target[0].value;
        obj.mail = e.target[1].value;
        obj.phone = e.target[2].value;
        obj.message = e.target[3].value;


        // fetch('http://127.0.0.1:5001/portofolio-187b3/europe-west1/contactMe', {
        //     method: 'POST',
        //     body: JSON.stringify(obj)
        // }).then(response => {
        //     return response.text();
        // }).then(result => {
        //     console.log(result);
        //     setResp(result);
        //     setFetching(false);
        // }).catch(err => {
        //     console.log(err);
        //     setFetching(false);
        // });

        fetch('https://contactme-iro5dkwkna-ew.a.run.app/portofolio-187b3/europe-west1/contactMe', {
            method: 'POST',
            body: JSON.stringify(obj)
        }).then(response => {
            return response.text();
        }).then(result => {
            console.log(result);
            setResp(result);
            setFetching(false);
        }).catch(err => {
            console.log(err);
            setFetching(false);
        });

    }, []);

    return (
        <section className="sp-contact">
            <h3>Contact</h3>
            <form className="sp-contact__form" onSubmit={handleSubmit}>
                <input className="sp-contact__form__input" type='text' name="name" placeholder="Nume" required />
                <input className="sp-contact__form__input" type='email' name="email" placeholder="E-mail" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
                <input className="sp-contact__form__input" type="tel" name="phone" placeholder="Telefon" required />
                <textarea className="sp-contact__form__textarea" placeholder="Mesaj" required />
                <input className="sp-contact__form__button" type="submit" value="Trimite" />
            </form>
            <a href="https://wa.me/40774492236" target="_blank" rel="noreferrer">
                <img src={wa} alt='Whatsapp' width={250}/>
            </a>
            
            {
                resp &&
                <h3 style={{ color: 'green' }}>{resp}</h3>
            }
            {
                fetching &&
                <h3>Se trimite...</h3>
            }
        </section>
    );
};

export default Contact;