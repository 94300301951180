import React from 'react';
import anpc from '../../assets/anpc.png';
import anpcc from '../../assets/anpcc.png';
import './Footer.scss';

const Footer = () => {
    return (
        <footer className='sp-footer'>
            <hr />
            <section>
                <div>

                </div>

                <div>
                    <a href='https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=R'>
                        <img src={anpc} alt='anpc' />
                    </a>
                    <a href='https://anpc.ro/ce-este-sal/'>
                        <img src={anpcc} alt='anpc' />
                    </a>
                </div>
            </section>
            <hr />
            <section>
                <p>Copyright © polstories.ro - 2023</p>
            </section>
        </footer>
    );
};

export default Footer;