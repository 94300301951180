import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Section from '../../components/Section/Section';
import { categories } from './categories';
import './Portfolio.scss';

const Portfolio = () => {
    const navigate = useNavigate();

    const handleNavigate = useCallback((path) => {
        navigate(path);
    }, [navigate]);

    return (
        <div className='sp-portfolio'>
            <section className='sp-portfolio__content'>
                {
                    categories.map(categorie => (
                        <Section key={categorie.title} title={categorie.title} url={categorie.url} onClick={() => handleNavigate(categorie.path)} />
                    ))
                }
            </section>
        </div>
    );
};

export default Portfolio;