import React from 'react';
import './Home.scss';

const Home = () => {
  return (
    <div className='sp-home'>
      <article>
        <h2>Sorin Popescu</h2>
        <p>“Photography is a way of feeling, of touching, of loving. What you have caught on film is captured forever… It remembers little things, long after you have forgotten everything.”</p>
        <p>– Aaron Siskind</p>
      </article>
    </div>
  );
};

export default Home;